<template>
  <div class="wrapper">
    <header class="header">
      <router-link :to="{name: 'ProjectsPage'}" class="header__logo-link">
        <BmIconLogo class="header__logo logo" />
        <div class="header__title">Brave Manager</div>
      </router-link>

      <nav class="header__nav nav">
        <div class="menu">
          <router-link
            :to="{name: 'ProjectsPage'}"
            active-class="menu__link_active"
            class="menu__link"
          >
            Проекты
          </router-link>

          <router-link
            :to="{name: 'EmployeesPage'}"
            active-class="menu__link_active"
            class="menu__link"
          >
            Сотрудники
          </router-link>
        </div>
        <div class="wrapper-dropdown">
          <BmButton round size="sm">
            <BmAvatar size="xl" :src="currentUser.avatar" />
            <template #dropdown>
              <div class="drop-menu">
                <div class="drop-menu__item" @click="logout">
                  <div class="flex items-center">Выйти</div>
                </div>
              </div>
            </template>
          </BmButton>
        </div>
      </nav>
    </header>
    <div class="container">
      <router-view />
    </div>
  </div>
</template>

<script>
import {mapActions} from 'vuex'

export default {
  name: 'DefaultLayout',

  data() {
    return {
      currentUser: {},
    }
  },

  methods: {
    ...mapActions({
      fetchCurrentUser: 'users/fetchCurrent',
    }),

    async logout() {
      try {
        localStorage.removeItem('token')
        await this.$router.push({name: 'LoginPage'})
      } catch (e) {
        console.log(e)
      }
    },

    async setCurrentUser() {
      this.currentUser = await this.fetchCurrentUser()
    },
  },

  created() {
    Promise.all([this.setCurrentUser()])
  },
}
</script>
